import * as React from "react";
import { PropertyReviews } from "@shared-ui/lodging-reviews";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { PropertyFilteredReviewsDialog } from "components/shared/PropertyFilteredReviewsDialog/PropertyFilteredReviewsDialog";
import { RefTarget } from "@shared-ui/ref-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { useDialog } from "@shared-ui/dialog-context";
import { PropertyReviewsWrapperProps } from "./typings";
import { useLocation } from "react-router-dom";
import { UitkHeading } from "@egds/react-core/text";
import { getTitle } from "./PropertyReviewsWrapperUtils";

const DEFAULT_NUMBER_OF_REVIEWS = 50;

const WrappedPropertyReviews = PropertyBexApiWrapper(PropertyReviews);

const PropertyReviewsComponent = ({
  customTitle,
  additionalInputs,
  openDialog,
  hideManagementResponse,
  context,
}: {
  customTitle: any;
  additionalInputs: any;
  openDialog: any;
  hideManagementResponse: any;
  context: any;
}) => {
  return (
    <RefTarget name="Reviews" tag="section" data-testid="property-reviews-wrapper">
      {customTitle && (
        <UitkSpacing padding={{ block: "three" }}>
          <div id="propertyReviewsTitle" data-testid="propertyReviewsTitle">
            <UitkHeading tag="h2" size={4}>
              {customTitle}
            </UitkHeading>
          </div>
        </UitkSpacing>
      )}
      <WrappedPropertyReviews
        additionalInputs={additionalInputs}
        context={context}
        onClickLink={openDialog}
        hideManagementResponse={hideManagementResponse}
      />
    </RefTarget>
  );
};

export const PropertyReviewsWrapper: React.FC<PropertyReviewsWrapperProps> = ({ context, templateComponent }) => {
  const id = templateComponent?.metadata.id || "";
  const [isDialogOpen, dialogActions, DialogComponent] = useDialog(`reviews-${id}`);
  const location = useLocation();
  const shouldHideForSEM = templateComponent?.config.hideForSEM || false;
  const showTitle = templateComponent?.config.showTitle || false;
  const searchParams = new URLSearchParams(location.search);
  const semdtl = searchParams.get("semdtl") ?? false;
  const semTraffic = shouldHideForSEM && semdtl;
  const customTitle = showTitle ? getTitle(context.searchContext?.location?.defaultName) : null;
  if (!templateComponent) {
    return null;
  }

  const additionalInputs = {
    pagination: {
      size: templateComponent.config.numberOfReviews || DEFAULT_NUMBER_OF_REVIEWS,
      startingIndex: templateComponent.config.startingIndex || 0,
    },
    includeRecentReviews: templateComponent.config.includeRecentReviews || false,
    searchCriteria: {
      primary: {
        dateRange: null,
        rooms: [{ adults: 2 }],
        destination: {
          regionId: String(context?.searchContext?.location?.parent?.id),
        },
      },
    },
  };
  return (
    <>
      {!semTraffic && (
        <PropertyReviewsComponent
          context={context}
          customTitle={customTitle}
          additionalInputs={additionalInputs}
          openDialog={dialogActions.openDialog}
          hideManagementResponse={templateComponent.config.hideManagementResponse}
        />
      )}
      <UitkSheetTransition isVisible={isDialogOpen}>
        <DialogComponent>
          <PropertyFilteredReviewsDialog context={context} onClose={dialogActions.closeDialog} />
        </DialogComponent>
      </UitkSheetTransition>
    </>
  );
};

export default PropertyReviewsWrapper;
